var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c('basic-form',{attrs:{"title":_vm.title,"isLoading":_vm.loading,"disableSave":_vm.form.status == 3},on:{"save":_vm.save,"update:isLoading":function($event){_vm.loading=$event},"update:is-loading":function($event){_vm.loading=$event}}},[_c('template',{slot:"fields"},[_c('div',{staticClass:"flex flex-col md:flex-row justify-center"},[_c('div',{staticClass:"w-full md:w-1/2 mr-3"},[_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('id_tournament')
                ? _vm.errors.first('id_tournament')
                : '',"variant":_vm.showValidation && _vm.errors.first('id_tournament') ? 'danger' : '',"label":'Torneo'}},[_c('t-rich-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"id_tournament","id":"id_tournament","placeholder":"Seleccione una opción","disabled":_vm.loading || _vm.fromEdit || _vm.form.status == 3,"noResultsText":"No se encontraron resultados","searchBoxPlaceholder":"Busca...","options":_vm.tournaments,"textAttribute":"name","valueAttribute":"id","variant":_vm.showValidation && _vm.errors.first('id_tournament')
                  ? 'danger'
                  : ''},on:{"input":_vm.selectTournament},model:{value:(_vm.form.id_tournament),callback:function ($$v) {_vm.$set(_vm.form, "id_tournament", $$v)},expression:"form.id_tournament"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('date_from')
                ? _vm.errors.first('date_from')
                : '',"variant":_vm.showValidation && _vm.errors.first('date_from') ? 'danger' : '',"label":'Fecha'}},[_c('t-datepicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"lang":"es","closeOnSelect":true,"data-vv-validate-on":"input","name":"date_from","id":"date_from","placeholder":"Seleccione la fecha","disabled":_vm.loading || _vm.form.status == 3,"variant":_vm.showValidation && _vm.errors.first('date_from') ? 'danger' : ''},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('start_hour')
                ? _vm.errors.first('start_hour')
                : '',"variant":_vm.showValidation && _vm.errors.first('start_hour') ? 'danger' : '',"label":'Hora de inicio'}},[_c('t-datepicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"lang":"es","user-format":"G:i K","date-format":"G:i K","data-vv-validate-on":"input","name":"start_hour","id":"start_hour","placeholder":"Seleccione la hora de inicio","disabled":_vm.loading || _vm.form.status == 3,"datepicker":false,"timepicker":"","amPm":"","variant":_vm.showValidation && _vm.errors.first('start_hour') ? 'danger' : ''},model:{value:(_vm.form.hour),callback:function ($$v) {_vm.$set(_vm.form, "hour", $$v)},expression:"form.hour"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('round')
                ? _vm.errors.first('round')
                : '',"variant":_vm.showValidation && _vm.errors.first('round') ? 'danger' : '',"label":'Ronda'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|numeric'),expression:"'required|numeric'"}],attrs:{"data-vv-validate-on":"input","name":"round","placeholder":"Ingrese la ronda","autocomplete":"off","disabled":_vm.loading || _vm.form.status == 3,"type":"text","variant":_vm.showValidation && _vm.errors.first('round') ? 'danger' : ''},model:{value:(_vm.form.round),callback:function ($$v) {_vm.$set(_vm.form, "round", $$v)},expression:"form.round"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('address')
                ? _vm.errors.first('address')
                : '',"variant":_vm.showValidation && _vm.errors.first('address') ? 'danger' : '',"label":'Dirección'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"address","placeholder":"Ingrese la dirección","autocomplete":"off","disabled":_vm.loading || _vm.form.status == 3,"type":"text","variant":_vm.showValidation && _vm.errors.first('address') ? 'danger' : ''},model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}})],1)],1),_c('div',{staticClass:"w-full relative md:w-1/2 mx-1"},[(_vm.form.id_tournament != null)?_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('id_category')
                ? _vm.errors.first('id_category')
                : '',"variant":_vm.showValidation && _vm.errors.first('id_category') ? 'danger' : '',"label":'Categoría - Subcategoría'}},[_c('t-rich-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"id_category","id":"id_category","placeholder":"Seleccione una opción","disabled":_vm.loading || _vm.fromEdit || _vm.form.status == 3,"noResultsText":"No se han añadido categorías a este torneo","searchBoxPlaceholder":"Busca...","options":_vm.categories,"textAttribute":"name","valueAttribute":"id","variant":_vm.showValidation && _vm.errors.first('id_category') ? 'danger' : ''},on:{"input":_vm.selectCategory},model:{value:(_vm.form.id_category),callback:function ($$v) {_vm.$set(_vm.form, "id_category", $$v)},expression:"form.id_category"}})],1):_vm._e(),(_vm.form.id_tournament != null && _vm.form.id_category != null)?_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('id_home_team')
                ? _vm.errors.first('id_home_team')
                : '',"variant":_vm.showValidation && _vm.errors.first('id_home_team') ? 'danger' : '',"label":'Equipo local'}},[_c('t-rich-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"id_home_team","id":"id_home_team","placeholder":"Seleccione una opción","disabled":_vm.loading || _vm.form.status == 3,"noResultsText":"No se han inscrito equipos a este torneo","searchBoxPlaceholder":"Busca...","options":_vm.academies,"textAttribute":"name","valueAttribute":"id","variant":_vm.showValidation && _vm.errors.first('id_home_team') ? 'danger' : ''},model:{value:(_vm.form.id_home_team),callback:function ($$v) {_vm.$set(_vm.form, "id_home_team", $$v)},expression:"form.id_home_team"}})],1):_vm._e(),(_vm.form.id_tournament != null && _vm.form.id_category != null)?_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('id_visit_team')
                ? _vm.errors.first('id_visit_team')
                : '',"variant":_vm.showValidation && _vm.errors.first('id_visit_team') ? 'danger' : '',"label":'Equipo visitante'}},[_c('t-rich-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"id_visit_team","id":"id_visit_team","placeholder":"Seleccione una opción","disabled":_vm.loading || _vm.form.status == 3,"noResultsText":"No se han inscrito equipos a este torneo","searchBoxPlaceholder":"Busca...","options":_vm.academies,"textAttribute":"name","valueAttribute":"id","variant":_vm.showValidation && _vm.errors.first('id_visit_team')
                  ? 'danger'
                  : ''},model:{value:(_vm.form.id_visit_team),callback:function ($$v) {_vm.$set(_vm.form, "id_visit_team", $$v)},expression:"form.id_visit_team"}})],1):_vm._e(),(_vm.deletable && _vm.form.status != 3)?_c('div',{staticClass:"absolute bottom-0 w-full flex justify-center"},[_c('t-button',{attrs:{"type":"button","variant":"error"},on:{"click":_vm.deleteElement}},[_vm._v("Eliminar partido")])],1):_vm._e()],1)])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }