<template>
  <div class="px-5">
    <Form
      title="Editar partido"
      ref="gameForm"
      :deletable="true"
      :loading.sync="loading"
      :fromEdit="true"
      @save="save"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      loading: true,
      form: {}
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    save() {
      let form = this.$refs.gameForm.form
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          this.loading = true

          //form.hour = 03:00 PM
          let hour = form.hour.indexOf('PM') > 0
            ? (parseInt(form.hour.substring(0,2)) + 12 == 24 ? 12 : parseInt(form.hour.substring(0,2)) + 12)
            : form.hour.substring(0,2)
          
          let minute = form.hour.substring(3,5)

          this.$store
            .dispatch("global/update", {
              payload: {
                id: form.id,
                id_category: form.id_category.split("$$")[0],
                id_subcategory: form.id_category.split("$$")[1],
                id_tournament: form.id_tournament,
                id_home_team: form.id_home_team,
                id_visit_team: form.id_visit_team,
                date: new Date(form.date).toISOString().split("T")[0],
                hour: hour + ':' + minute,
                round: form.round,
                location: form.location
              },
              route: "/game/update",
              module: "game"
            })
            .then((response) => {
              notify().then(({ notification }) => {
                notification("master-update", response.code, this.$snotify)
              })
              if ([200, 201].includes(response.code))
                this.$router.push("/partidos")
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              notify().then(({ notification }) => {
                notification("error", error.code, this.$snotify)
              })
            })
        }
      })
    },
    getItem() {
      this.$store
        .dispatch("global/getItem", {
          route: "/game",
          module: "game",
          id: this.$route.params.id
        })
        .then((response) => {
          this.form = response.data
          this.form.id_category = response.data.id_category + '$$' + response.data.id_subcategory
          this.$refs.gameForm.form = this.form

          if (this.form.status == 3) {
            notify().then(({ notification }) => {
              notification("error", 100, this.$snotify)
            })
          }

          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {
    this.getItem()
  }
}
</script>
