<template>
  <div class="px-5">
    <basic-form :title="title" @save="save" :isLoading.sync="loading" :disableSave="form.status == 3">
      <template slot="fields">
        <div class="flex flex-col md:flex-row justify-center">
          <div class="w-full md:w-1/2 mr-3">
            <t-input-group
              :feedback="
                showValidation && errors.first('id_tournament')
                  ? errors.first('id_tournament')
                  : ''
              "
              :variant="
                showValidation && errors.first('id_tournament') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Torneo'"
            >
              <t-rich-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.id_tournament"
                name="id_tournament"
                id="id_tournament"
                placeholder="Seleccione una opción"
                :disabled="loading || fromEdit || form.status == 3"
                noResultsText="No se encontraron resultados"
                searchBoxPlaceholder="Busca..."
                :options="tournaments"
                @input="selectTournament"
                textAttribute="name"
                valueAttribute="id"
                :variant="
                  showValidation && errors.first('id_tournament')
                    ? 'danger'
                    : ''
                "
              ></t-rich-select>
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('date_from')
                  ? errors.first('date_from')
                  : ''
              "
              :variant="
                showValidation && errors.first('date_from') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Fecha'"
            >
              <t-datepicker
                v-model="form.date"
                lang="es"
                :closeOnSelect="true"
                v-validate="'required'"
                data-vv-validate-on="input"
                name="date_from"
                id="date_from"
                placeholder="Seleccione la fecha"
                :disabled="loading || form.status == 3"
                :variant="
                  showValidation && errors.first('date_from') ? 'danger' : ''
                "
              >
              </t-datepicker>
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('start_hour')
                  ? errors.first('start_hour')
                  : ''
              "
              :variant="
                showValidation && errors.first('start_hour') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Hora de inicio'"
            >
              <t-datepicker
                v-model="form.hour"
                lang="es"
                user-format="G:i K"
                date-format="G:i K"
                v-validate="'required'"
                data-vv-validate-on="input"
                name="start_hour"
                id="start_hour"
                placeholder="Seleccione la hora de inicio"
                :disabled="loading || form.status == 3"
                :datepicker="false"
                timepicker
                amPm
                :variant="
                  showValidation && errors.first('start_hour') ? 'danger' : ''
                "
              >
              </t-datepicker>
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('round')
                  ? errors.first('round')
                  : ''
              "
              :variant="showValidation && errors.first('round') ? 'danger' : ''"
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Ronda'"
            >
              <t-input
                v-validate="'required|numeric'"
                data-vv-validate-on="input"
                v-model="form.round"
                name="round"
                placeholder="Ingrese la ronda"
                autocomplete="off"
                :disabled="loading || form.status == 3"
                type="text"
                :variant="
                  showValidation && errors.first('round') ? 'danger' : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('address')
                  ? errors.first('address')
                  : ''
              "
              :variant="
                showValidation && errors.first('address') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Dirección'"
            >
              <t-input
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.location"
                name="address"
                placeholder="Ingrese la dirección"
                autocomplete="off"
                :disabled="loading || form.status == 3"
                type="text"
                :variant="
                  showValidation && errors.first('address') ? 'danger' : ''
                "
              />
            </t-input-group>
          </div>

          <div class="w-full relative md:w-1/2 mx-1">
            <t-input-group
              v-if="form.id_tournament != null"
              :feedback="
                showValidation && errors.first('id_category')
                  ? errors.first('id_category')
                  : ''
              "
              :variant="
                showValidation && errors.first('id_category') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Categoría - Subcategoría'"
            >
              <t-rich-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.id_category"
                name="id_category"
                id="id_category"
                placeholder="Seleccione una opción"
                :disabled="loading || fromEdit || form.status == 3"
                noResultsText="No se han añadido categorías a este torneo"
                searchBoxPlaceholder="Busca..."
                :options="categories"
                @input="selectCategory"
                textAttribute="name"
                valueAttribute="id"
                :variant="
                  showValidation && errors.first('id_category') ? 'danger' : ''
                "
              ></t-rich-select>
            </t-input-group>
            
            <t-input-group
              v-if="form.id_tournament != null && form.id_category != null"
              :feedback="
                showValidation && errors.first('id_home_team')
                  ? errors.first('id_home_team')
                  : ''
              "
              :variant="
                showValidation && errors.first('id_home_team') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Equipo local'"
            >
              <t-rich-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.id_home_team"
                name="id_home_team"
                id="id_home_team"
                placeholder="Seleccione una opción"
                :disabled="loading || form.status == 3"
                noResultsText="No se han inscrito equipos a este torneo"
                searchBoxPlaceholder="Busca..."
                :options="academies"
                textAttribute="name"
                valueAttribute="id"
                :variant="
                  showValidation && errors.first('id_home_team') ? 'danger' : ''
                "
              ></t-rich-select>
            </t-input-group>

            <t-input-group
              v-if="form.id_tournament != null && form.id_category != null"
              :feedback="
                showValidation && errors.first('id_visit_team')
                  ? errors.first('id_visit_team')
                  : ''
              "
              :variant="
                showValidation && errors.first('id_visit_team') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Equipo visitante'"
            >
              <t-rich-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.id_visit_team"
                name="id_visit_team"
                id="id_visit_team"
                placeholder="Seleccione una opción"
                :disabled="loading || form.status == 3"
                noResultsText="No se han inscrito equipos a este torneo"
                searchBoxPlaceholder="Busca..."
                :options="academies"
                textAttribute="name"
                valueAttribute="id"
                :variant="
                  showValidation && errors.first('id_visit_team')
                    ? 'danger'
                    : ''
                "
              ></t-rich-select>
            </t-input-group>

            <div
              class="absolute bottom-0 w-full flex justify-center"
              v-if="deletable && form.status != 3"
            >
              <t-button type="button" variant="error" @click="deleteElement"
                >Eliminar partido</t-button
              >
            </div>
          </div>
        </div>
      </template>
    </basic-form>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
export default {
  components: {},
  props: {
    title: {
      type: String
    },
    deletable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    },
    fromEdit: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      form: {
        id_tournament: null,
        id_category: null,
        id_home_team: null,
        id_visit_team: null
      },
      categories: [],
      showValidation: false
    }
  },
  computed: {
    /*categories() {
      return this.form.id_tournament
        ? this.$store.getters["global/getItems"](
          "tournamentCategory",
          "unpaginated_elements")
        : []
    },*/
    /*subcategories() {
      return this.form.id_tournament
        ? this.$store.getters["global/getItems"](
          "subcategory",
          "unpaginated_elements")
        : []
    },*/
    academies() {
      return this.form.id_tournament && this.form.id_category
        ? this.$store.getters["global/getItems"](
          "tournamentAcademy",
          "unpaginated_elements")
          .map(x => {
            return {
              id: x.id_academy,
              name: x.academy.name
            }
          }).sort((a,b) => {
            if (a.name > b.name) {
              return 1
            }
            else {
              return -1
            }
          })
        : []
    },
    tournaments() {
      return this.$store.getters["global/getItems"](
        "tournament",
        "unpaginated_elements"
      )
    }
  },
  methods: {
    getTournaments(route, module, master) {
      this.$store
        .dispatch("global/getItems", {
          module: module,
          route: route,
          noPaginate: true,
          isMaster: master,
          params: {
            active: 1,
            not_status: 1,
            order_key: "name",
            order_value: "asc"
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getCategories(route, module, master) {
      this.$store
        .dispatch("global/getItems", {
          module: module,
          route: route,
          noPaginate: true,
          isMaster: master,
          params: {
            active: 1,
            id_tournament: this.form.id_tournament,
            order_key: "id_category",
            order_value: "asc"
          }
        })
        .then((response) => {
          this.categories = response.data.map(
            (x) => {
              return {
                id: x.id_category + "$$" + x.id_subcategory,
                name: x.category.name + ' - ' + x.subcategory.name
              }
            }
          )
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getAcademies(route, module, master) {
      let id_category = this.form.id_category.split("$$")[0]
      let id_subcategory = this.form.id_category.split("$$")[1]

      this.$store
        .dispatch("global/getItems", {
          module: module,
          route: route,
          noPaginate: true,
          isMaster: master,
          params: {
            active: 1,
            id_tournament: this.form.id_tournament,
            id_category: id_category,
            id_subcategory: id_subcategory,
            order_key: "id_academy",
            order_value: "asc"
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          this.$emit("save")
        }
      })
    },

    selectTournament() {
      if (!this.fromEdit) {
        this.form.id_category = null
        this.form.id_home_team = null
        this.form.id_visit_team = null
      }

      this.getCategories("/tournament_category/all", "tournamentCategory", false)
    },
    selectCategory() {
      if (!this.fromEdit) {
        this.form.id_home_team = null
        this.form.id_visit_team = null
      }
      
      if (this.form.id_category) {
        this.getAcademies("/tournament_academy/all", "tournamentAcademy", false)
      }
    },

    deleteElement() {
      this.$dialog
        .confirm({
          title: "¿Estás seguro?",
          text: `Esta acción eliminará permanentemente el partido.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          if (result.isOk) {
            this.$store
              .dispatch("global/changeStatus", {
                payload: {
                  id: this.form.id,
                  status: 2
                },
                route: "/game",
                module: "game"
              })
              .then((response) => {
                notify().then(({ notification }) => {
                  notification(
                    "master-status-delete",
                    response.code,
                    this.$snotify
                  )
                })
                if (response.code === 200) this.$router.push("/partidos")
              })
              .catch((error) => {
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          }
        })
    }
  },
  created() {
    this.getTournaments("/tournament/all", "tournament", false)
  }
}
</script>
